<!--地标查询-->
<template>

</template>

<script>
export default {
  name: 'index'
}
</script>

<style scoped>

</style>
